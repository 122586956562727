exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-uk-js": () => import("./../../../src/pages/about.uk.js" /* webpackChunkName: "component---src-pages-about-uk-js" */),
  "component---src-pages-about-us-editorial-team-uk-js": () => import("./../../../src/pages/about-us/editorial-team.uk.js" /* webpackChunkName: "component---src-pages-about-us-editorial-team-uk-js" */),
  "component---src-pages-blackfriday-js": () => import("./../../../src/pages/blackfriday.js" /* webpackChunkName: "component---src-pages-blackfriday-js" */),
  "component---src-pages-blackfriday-success-js": () => import("./../../../src/pages/blackfriday-success.js" /* webpackChunkName: "component---src-pages-blackfriday-success-js" */),
  "component---src-pages-collagen-calculator-uk-js": () => import("./../../../src/pages/collagen-calculator.uk.js" /* webpackChunkName: "component---src-pages-collagen-calculator-uk-js" */),
  "component---src-pages-contact-uk-js": () => import("./../../../src/pages/contact.uk.js" /* webpackChunkName: "component---src-pages-contact-uk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pl-js": () => import("./../../../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-index-ua-js": () => import("./../../../src/pages/index.ua.js" /* webpackChunkName: "component---src-pages-index-ua-js" */),
  "component---src-pages-index-uk-js": () => import("./../../../src/pages/index.uk.js" /* webpackChunkName: "component---src-pages-index-uk-js" */),
  "component---src-pages-kalkulator-kolagenu-pl-js": () => import("./../../../src/pages/kalkulator-kolagenu.pl.js" /* webpackChunkName: "component---src-pages-kalkulator-kolagenu-pl-js" */),
  "component---src-pages-kalkulyator-kolagenu-ua-js": () => import("./../../../src/pages/kalkulyator-kolagenu.ua.js" /* webpackChunkName: "component---src-pages-kalkulyator-kolagenu-ua-js" */),
  "component---src-pages-kontakt-pl-js": () => import("./../../../src/pages/kontakt.pl.js" /* webpackChunkName: "component---src-pages-kontakt-pl-js" */),
  "component---src-pages-kontakt-ua-js": () => import("./../../../src/pages/kontakt.ua.js" /* webpackChunkName: "component---src-pages-kontakt-ua-js" */),
  "component---src-pages-o-nas-pl-js": () => import("./../../../src/pages/o-nas.pl.js" /* webpackChunkName: "component---src-pages-o-nas-pl-js" */),
  "component---src-pages-o-nas-redakcja-pl-js": () => import("./../../../src/pages/o-nas/redakcja.pl.js" /* webpackChunkName: "component---src-pages-o-nas-redakcja-pl-js" */),
  "component---src-pages-opinie-pl-js": () => import("./../../../src/pages/opinie.pl.js" /* webpackChunkName: "component---src-pages-opinie-pl-js" */),
  "component---src-pages-pro-nas-redaktsiya-ua-js": () => import("./../../../src/pages/pro-nas/redaktsiya.ua.js" /* webpackChunkName: "component---src-pages-pro-nas-redaktsiya-ua-js" */),
  "component---src-pages-pro-nas-ua-js": () => import("./../../../src/pages/pro-nas.ua.js" /* webpackChunkName: "component---src-pages-pro-nas-ua-js" */),
  "component---src-pages-reviews-uk-js": () => import("./../../../src/pages/reviews.uk.js" /* webpackChunkName: "component---src-pages-reviews-uk-js" */),
  "component---src-pages-ua-404-js": () => import("./../../../src/pages/ua/404.js" /* webpackChunkName: "component---src-pages-ua-404-js" */),
  "component---src-pages-uk-404-js": () => import("./../../../src/pages/uk/404.js" /* webpackChunkName: "component---src-pages-uk-404-js" */),
  "component---src-pages-vidhuky-ua-js": () => import("./../../../src/pages/vidhuky.ua.js" /* webpackChunkName: "component---src-pages-vidhuky-ua-js" */),
  "component---src-pages-w-kalkulator-kolagenowy-js": () => import("./../../../src/pages/w/kalkulator-kolagenowy.js" /* webpackChunkName: "component---src-pages-w-kalkulator-kolagenowy-js" */),
  "component---src-templates-author-page-author-page-js": () => import("./../../../src/templates/AuthorPage/AuthorPage.js" /* webpackChunkName: "component---src-templates-author-page-author-page-js" */),
  "component---src-templates-blog-pages-blog-list-js": () => import("./../../../src/templates/Blog/pages/BlogList.js" /* webpackChunkName: "component---src-templates-blog-pages-blog-list-js" */),
  "component---src-templates-blog-pages-blog-post-js": () => import("./../../../src/templates/Blog/pages/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-pages-blog-post-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/Page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */)
}

